import hero from '../images/about_hero.png';
import sc_logo_desk from '../images/logo_red.png';
import believe_hero from '../images/about_believe.png';
import values_hero from '../images/about_values.png';
import story_hero from '../images/about_story.png';
import women_hero from '../images/about_women.png';
import approach_hero from '../images/about_approach.png';
import Header from "../sections/Header.js";
import Footer from "../sections/Footer.js";
import { useRef } from "react";

const About = () => {
  const aboutPageRef = useRef();
  const believeComponent = useRef();
  const valuesComponent = useRef();
  const storyComponent = useRef();
  const womenComponent = useRef();
  const approachComponent = useRef();

  const arrowScroll = (whichContainer) => {
    whichContainer.current.scrollIntoView({ behavior: "smooth" });
  };
    return  (<div className="">
       <Header whichRef={aboutPageRef} whichColor={"#ffe1d9"} />
    {/* ---------------------------------------------------------------------------------------------------------------
    ---------------------------------------OPENING About us content--------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div className="bg-[#eab1fc]">
        <div className="mx-auto max-w-[1240px]">
        <a href="/" className='0'><img src={sc_logo_desk} className="absolute w-[90px] mt-[1rem] z-[21]" alt="secret-circle-logo" /></a>
          <div className="flex  md:flex-row items-center w-full bg-[#eab1fc] overflow-hidden"> 
            <div className="max-w-[1240px]  absolute md:mb-0 md:mt-0 w-[100%] "> 

            <h1 className="xs:z-10 xs:relative md:static md:ml-0 xs:text-left md:text-center xs:mb-0  xs:mr-0  xs:ml-[2rem] s:ml-[3rem] sm:ml-[4rem]  md:mt-0  font-sans antialiased mds:text-[4.8rem] mds:leading-[2.2rem] s:text-[3rem] xss:text-[2.8rem] xss:leading-[2.2rem] align-middle text-[#b24029]">About us
              </h1>
              <p className="hidden md:block xs:z-10  h-[100%] bottom-[-526%] absolute mx-auto w-[100%] text-center xs:mb-0     md:mt-0  font-sans antialiased mds:text-[0.83rem] md:text-[0.7rem] mds:leading-[1.2rem] s:text-[0.65rem] xss:text-[0.6rem] xss:leading-[1.2rem] align-middle text-[#b24029]"><span><button className='underline underline-offset-3' onClick={() => arrowScroll(believeComponent)}>We believe</button></span>&nbsp;&nbsp;&nbsp;&nbsp;<span><button className='underline underline-offset-3' onClick={() => arrowScroll(valuesComponent)}>Our values</button></span>&nbsp;&nbsp;&nbsp;&nbsp;<span><button className='underline underline-offset-3' onClick={() => arrowScroll(storyComponent)}>Our story</button></span>&nbsp;&nbsp;&nbsp;&nbsp;<span><button className='underline underline-offset-3' onClick={() => arrowScroll(womenComponent)}>The women we support</button></span>&nbsp;&nbsp;&nbsp;&nbsp;<span><button className='underline underline-offset-3' onClick={() => arrowScroll(approachComponent)}>Our approach</button></span> 
              </p>
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
           
            <div className=" md:mt-0  md:mb-0 w-[100%]  "> 
              {/* <img src={hero} className=" xs:relative md:static xs:left-[20%] md:left-0  xs:mx-auto md:min-h-[388px] md:max-h-[100vh] h-[auto] hero-img float-right" alt="hero" /> */}
              <img src={hero} className=" xs:relative md:static ss:left-[20%] xs:left-[15%] md:left-0  xs:mx-auto md:min-h-[388px] md:max-h-[100vh] ss:h-[100%] xs:h-[188px] ss:mt-0 xs:mt-[18%] hero-img float-right" alt="hero" />
            </div>
           
          </div>
        </div>
      </div>


    {/* ---------------------------------------------------------------------------------------------------------------
    --------------------------------------- We believe ---------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div ref={believeComponent} className="bg-[#ffe1d9]">
        <div  ref={aboutPageRef}  className="mx-auto max-w-[1240px] md:pt-[7.4rem]">
          <div className="flex xs:flex-col md:flex-row xs:items-center md:items-start w-full bg-[#ffe1d9] md:min-h-[550px] overflow-hidden"> 
            <div className=" xs:mb-[0vh] xs:mt-[5vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12"> 
              <h1 className="md:mb-14 xs:mb-0 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-0  md:ml-[4.5rem] font-sans antialiased text-[4.9rem] xl:text-[4.9rem] lgxx:text-[4.9rem] lgx:text-[4.7rem] lg:text-[4.3rem] mdx:text-[3.9rem] mds:text-[3.5rem] md:text-[3rem]  sm:text-[3rem] s:text-[3rem] xss:text-[2.9rem] leading-[4.5rem] xl:leading-[4.5rem] lgxx:leading-[4.5rem] lgx:leading-[4.3rem] lg:leading-[3.9rem] mdx:leading-[3.5rem] mds:leading-[3.1rem] md:leading-[2.6rem] sm:leading-[2.6rem] s:leading-[2.6rem] xss:leading-[2.6rem]  align-middle text-[#b24029]">We put<br className="hidden md:block"></br> women’s<br className="hidden md:block"></br> health first
              </h1>
              <p className="xs:mt-4 mds:w-[360px] first-letter:xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-[4.5rem]  font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.54rem] tracking-[-0.01em] align-middle text-[#b24029]">
              <ol className="list-disc pl-4">
                  <li className="pb-3">When women lead healthy lives, all of society benefits.</li>
                  <li className="pb-3">For over 40 years, we have advocated for equal access to healthcare and championed women’s health through peer support and service provision.</li>
                  <li className="pb-3">As a charity based in London, we work with women from a range of backgrounds and experiences. Whatever a woman’s circumstances, we walk alongside her as an individual, building trusting relationships that enable women and their families to thrive.</li>
                </ol>
              </p>
            </div>
              {/* --------------------------------------------------------------------------------------------------------- */}
            <div className=" md:mr-0 md:mb-[0vh] md:mt-[0vh] xs:mb-[5vh] xs:mt-[5vh] xs:w-[90%] md:w-6/12 xs:ml-[10%] md:ml-0"> 
              <img src={believe_hero} className=" max-h-[671px] h-[auto]" alt="hero" />
            </div>
  
          </div>
        </div>
      </div>
      
    {/* ---------------------------------------------------------------------------------------------------------------
    --------------------------------------- Our values --------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div className="bg-[#ffe1d9]">
        <div ref={valuesComponent} className="mx-auto max-w-[1240px] xs:pt-[0vh] md:pt-[9rem] md:pb-[10vh]">
          <div className="flex xs:flex-col-reverse xs:items-center md:items-start md:flex-row w-full bg-[#ffe1d9] md:min-h-[550px] overflow-hidden"> 
            <div className="   xs:mb-[5vh] xs:mt-[5vh] md:mt-0  md:mb-0  xs:w-[90%] md:w-[65%] xs:mr-[10%] md:mr-0 ">
              <img src={values_hero} className=" xs:mx-auto max-h-[671px] " alt="healing_image" />
            </div>
            
            {/* --------------------------------------------------------------------------------------------------------- */}
            <div  className=" xs:mb-[0vh] xs:mt-[5vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12 md:mx-[1.5rem]"> 
              <h1 className="md:mb-14  xs:mb-0 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-7 lgx:w-[392px] font-sans antialiased text-[4.9rem] xl:text-[4.9rem] lgxx:text-[4.9rem] lgx:text-[4.7rem] lg:text-[4.3rem] mdx:text-[3.9rem] mds:text-[3.5rem] md:text-[3rem]  sm:text-[3rem] s:text-[3rem] xss:text-[2.9rem] leading-[4.5rem] xl:leading-[4.5rem] lgxx:leading-[4.5rem] lgx:leading-[4.3rem] lg:leading-[3.9rem] mdx:leading-[3.5rem] mds:leading-[3.1rem] md:leading-[2.6rem] sm:leading-[2.6rem] s:leading-[2.6rem] xss:leading-[2.6rem] align-middle text-[#b24029]">Our values
              </h1>
              <p className="xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7 lgx:w-[355px] font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.54rem] tracking- [-0.01em] align-middle text-[#b24029]">
              We’re inclusive - breaking down barriers to reach and empower the most marginalised:
              <br /><br />
              <ol className="list-disc pl-4">
                  <li className="pb-3">We believe in the power of trust: behaving with integrity, reliably offering a safe and supportive spaces</li>
                  <li className="pb-3">We’re courageous - challenging norms and exploring new ideas to create change</li>
                  <li className="pb-3">We’re reflective - always listening and learning to improve</li>
                  {/* <li className="pb-3"><b>Working collaboratively:</b> we are inclusive and embrace differences</li> */}
                  {/* <li className="pb-3"><b>Reflective:</b> we always listen, learn and improve</li> */}
                </ol>
              </p>
              
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------------------------------------------------------------
    --------------------------------------- Our story --------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
    <div className="bg-[#ff794e]">
      <div className="bg-[#ff794e]">
        <div ref={storyComponent} className="mx-auto max-w-[1240px]">

        <div className="flex xs:flex-col md:flex-row items-start xs:mx-auto xs:w-4/5 md:w-[100%] overflow-hidden pt-[80px] pb-[50px] md:px-[75px] xs:px-[0px]"> 
            <div className="   xs:w-5/5 md:w-4/12"> 
              <h1 className="first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-0  font-sans antialiased mds:text-[4.8rem] mds:leading-[4.2rem] s:text-[3rem] s:leading-[4rem] xss:text-[2.3rem] xss:leading-[2.9rem] align-middle text-[#ffe1d9]">Our story
              </h1>
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className="   xs:w-5/5 md:w-8/12"> 
              <p className="max-w-[570px] xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-0 md:ml-0  font-sans antialiased text-[1.7rem] leading-[2rem] tracking-[-0.01em] align-middle text-[#ffe1d9]">
                Our charity began as a way to help Bangladeshi and Somalian women in Tower Hamlets access maternity health services and quality healthcare.
              </p>
            </div>
          </div>

          <div className="w-[100%] md:px-[75px]"> 
            <img src={story_hero} className=" xs:mx-auto max-h-[670px] h-[auto]" alt="hero" />
          </div>

          <div className="flex xs:flex-col md:flex-row items-start w-full overflow-hidden  pt-[50px] pb-[80px] md:px-[75px] xs:px-[50px]"> 
            <div className="   xs:w-5/5 md:w-4/12"> 
              <p className="xs:mt-0 xs:mr-0 xs:ml-0 md:mt-0 md:mr-5 md:ml-0  font-sans antialiased md:text-[0.9rem] md:leading-[1.1rem] xs:text-[1.3rem] xs:leading-[1.6rem] tracking-[-0.01em] align-middle text-[#ffe1d9] pt-3 border-t-[1px]">
                1981<br />
                A group of determined local health and community workers set up the Maternity Services Liaison Scheme (MSLS), with support from the Tower Hamlets Association for Racial Justice.
              </p>
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className="   xs:w-5/5 md:w-4/12"> 
              <p className="xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-5 md:ml-5  font-sans antialiased md:text-[0.9rem] md:leading-[1.1rem] xs:text-[1.3rem] xs:leading-[1.6rem] tracking-[-0.01em] align-middle text-[#ffe1d9] pt-3 border-t-[1px]">
                1994<br />
                We changed our name to WHFS (Women’s Health & Family Services) to more accurately reflect the breadth of women’s health support programmes we offer to local women.
              </p>
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className="   xs:w-5/5 md:w-4/12"> 
              <p className="xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-0 md:ml-5 font-sans antialiased md:text-[0.9rem] md:leading-[1.1rem] xs:text-[1.3rem] xs:leading-[1.6rem] tracking-[-0.01em] align-middle text-[#ffe1d9] pt-3 border-t-[1px]">
                Today<br />
                We continue to follow in the footsteps of our founders, as a multi-ethnic women led organisation, supporting minoritised women to receive better maternity care and advocating for fairness and equality in accessing women’s healthcare.
              </p>
            </div>
          </div>

        </div>
      </div>
    </div>
    {/* ---------------------------------------------------------------------------------------------------------------
    --------------------------------------- The women we support ---------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div ref={womenComponent} className="bg-[#ffe1d9]">
        <div className="mx-auto max-w-[1240px] md:pt-[7.4rem]">
          <div className="flex xs:flex-col md:flex-row xs:items-center md:items-start w-full bg-[#ffe1d9] md:min-h-[550px] overflow-hidden"> 
            <div className=" xs:mb-[0vh] xs:mt-[5vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12"> 
              <h1 className="md:mb-14 xs:mb-0 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-0  md:ml-[4.5rem] font-sans antialiased text-[4.9rem] xl:text-[4.9rem] lgxx:text-[4.9rem] lgx:text-[4.7rem] lg:text-[4.3rem] mdx:text-[3.9rem] mds:text-[3.5rem] md:text-[3rem]  sm:text-[3rem] s:text-[3rem] xss:text-[2.9rem] leading-[4.5rem] xl:leading-[4.5rem] lgxx:leading-[4.5rem] lgx:leading-[4.3rem] lg:leading-[3.9rem] mdx:leading-[3.5rem] mds:leading-[3.1rem] md:leading-[2.6rem] sm:leading-[2.6rem] s:leading-[2.6rem] xss:leading-[2.6rem]  align-middle text-[#b24029]">The women<br className="hidden md:block"></br> we support
              </h1>
              <p className="xs:mt-4 mds:w-[360px] first-letter:xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-[4.5rem]  font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.54rem] tracking-[-0.01em] align-middle text-[#b24029]">
              The remarkable women we support find themselves in difficult circumstances of multiple complexities – no support network, living in poor temporary accommodation, newly arrived in the UK with little English, no knowledge of the maternity and women’s healthcare systems, homeless, having experienced gender violence.
                <br /><br />
                Each woman deserves to have good mental, physical and social wellbeing and to receive support in rebuilding a better life for herself and her family.
                <br /><br />
                Anyone who uses our service can expect to:
                <br /><br />
                <ol className="list-disc pl-4">
                  <li className="pb-3">Easily access healthcare that meets her needs</li>
                  <li className="pb-3">Be listened to</li>
                  <li className="pb-3">Be respected</li>
                  <li className="pb-3">Be treated with empathy and kindness</li>
                </ol>
              </p>
              
            </div>
              {/* --------------------------------------------------------------------------------------------------------- */}
            <div className=" md:mr-0 md:mb-[0vh] md:mt-[0vh] xs:mb-[5vh] xs:mt-[5vh] xs:w-[90%] md:w-6/12 xs:ml-[10%] md:ml-0"> 
              <img src={women_hero} className=" max-h-[671px] h-[auto]" alt="hero" />
            </div>
             
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------------------------------------------------------------
    --------------------------------------- Our approach --------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div ref={approachComponent} className="bg-[#ffe1d9]">
        <div className="mx-auto max-w-[1240px] xs:pt-[0vh] md:pt-[8rem] md:pb-[10vh]">
          <div className="flex xs:flex-col-reverse xs:items-center md:items-start md:flex-row w-full bg-[#ffe1d9] md:min-h-[550px] overflow-hidden"> 
            <div className="   xs:mb-[5vh] xs:mt-[5vh] md:mt-0  md:mb-0  xs:w-[90%] md:w-[65%] xs:mr-[10%] md:mr-0 ">
              <img src={approach_hero} className=" xs:mx-auto max-h-[671px] " alt="healing_image" />
            </div>
            
            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className=" xs:mb-[0vh] xs:mt-[0vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12 md:mx-[1.5rem]"> 
              <h1 className="md:mb-14 xs:mb-0  first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-7 lgx:w-[392px] font-sans antialiased text-[4.9rem] xl:text-[4.9rem] lgxx:text-[4.9rem] lgx:text-[4.7rem] lg:text-[4.3rem] mdx:text-[3.9rem] mds:text-[3.5rem] md:text-[3rem]  sm:text-[3rem] s:text-[3rem] xss:text-[2.9rem] leading-[4.5rem] xl:leading-[4.5rem] lgxx:leading-[4.5rem] lgx:leading-[4.3rem] lg:leading-[3.9rem] mdx:leading-[3.5rem] mds:leading-[3.1rem] md:leading-[2.6rem] sm:leading-[2.6rem] s:leading-[2.6rem] xss:leading-[2.6rem] align-middle text-[#b24029]">Our approach
              </h1>
              <p className="xs:mt-4 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7 lgx:w-[355px] font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.54rem] tracking- [-0.01em] align-middle text-[#b24029]">
              <ol className="list-disc pl-4">
                  <li className="pb-3">We walk alongside each woman to support her as an individual.</li>
                  <li className="pb-3">We provide advocacy, practical and emotional support with empathy and without judgement.</li>
                  <li className="pb-3">We create space for women to think, plan, heal and rebuild their confidence, advocate for their own healthcare needs and when asked, advocate on their behalf. </li>
                  <li className="pb-3">We build partnerships and learning spaces so unheard voices can be heard and women can create change.</li>
                  <li className="pb-3">Everything we do is relational, culturally and trauma informed.</li>
                </ol>
              </p>
              
            </div>
          </div>
        </div>
      </div>

    {/* --------------------------------------------------content 5 end--------------------------------------------------- */}
    <Footer />
    </div> 
  );
};

  
  export default About;