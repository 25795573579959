import { useRef, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import menu_bg from "../images/menu_hero.png";
import header_logo from "../images/logo_red.png";
// import englishIcon from "../images/english_Icon.png";
// import englishIconW from "../images/english_IconW.png";
import exitIcon from "../images/exit_Icon.png";
import exitIconM from "../images/exit_IconM.png";
import PropTypes from "prop-types";
import gsap from "gsap";
// import sc_logo_desk from '../secretCircle_logo_desktop.png';
const Layout = (props) => {
  const overlayMenu = useRef();
  const backButton = useRef();
  const backButton2 = useRef();
  const mobDonate = useRef();
  const bgColor = useRef();
  const tl = useRef();
  const topNav = useRef();
  const menuBtn = useRef();
  const exitBtn = useRef();
  const changeExit = useRef();
  const supportBtn = useRef();
  const exitBtn2 = useRef();
  const supportBtn2 = useRef();
  
   const styles = { left: "0px", };

  //menus = white
  //2 image changes

  const [menuLogo, setMenuLogo] = useState(
    "hidden w-[100px] pb-[2.2rem] absolute "
  );

  const [menuConfig, setMenuConfig] = useState(
    "text-[#b24029]  border-2 border-[#b24029] focus:outline-none font-medium rounded-full text-sm px-4 py-1.5 text-center mr-3"
  );

  const [getSupportBtn, setSupportBtn] = useState(
    "text-[#ffe1d9] bg-[#b24029] focus:outline-none font-medium rounded-full text-sm px-5 pt-[0.4379rem] pb-[0.4379rem] text-center mr-3"
  );

  const [getMobDonate, setMobDonate] = useState(
    "border-2 text-[#b24029] border-[#b24029] focus:outline-none font-medium rounded-full xsss:text-sm xs:text-[0.675rem] xs:leading-[0.875rem] xsss:px-5 xs:px-[0.9rem] xsss:py-2.5 xs:py-[0.525rem] text-center mr-3"
  );


  const [getSupportBtn2, setSupportBtn2] = useState(
    "text-[#ffe1d9] bg-[#b24029] focus:outline-none font-medium rounded-full xsss:text-sm xs:text-[0.675rem] xs:leading-[1.05rem] xsss:px-5 xs:px-[0.9rem] xsss:py-2.5 xs:py-[0.525rem] text-center mr-3"
  );


  const [exitBtnClass, setExitBtnClass] = useState(
    "text-[#ffe1d9] bg-[#b24029] focus:outline-none font-medium rounded-full text-sm px-5 pt-[0.4379rem] pb-[0.4379rem] text-center"
  );

  // const [englishIconImg, setEnglishIconImg] = useState(englishIcon);
  const [exitIconImg, SetExitIcon] = useState(exitIcon);

  const [menuTop, setMenuTop] = useState(
    // "px-2 sm:px-4 py-[1rem] w-full z-20 top-0 left-0 border-gray-200 absolute bg-[#e7a8fc]"
    "px-2 sm:px-4 py-[1rem] w-full z-[20] top-0 left-0 border-gray-200 absolute "
  );

  const [menuBotMob, setMenuBotMob] = useState(
    // "md:hidden fixed bottom-0 xs:h-[4.112rem] xsss:h-[4.506rem] w-[768px] "

    "md:hidden fixed bottom-0 xs:h-[4.112rem] xsss:h-[4.506rem] w-[768px] bg-[" +
            props.whichColor +
            "]"
  );

  // const [ donorBoxDiv, setDonorBoxDiv] = useState(
  //   " hidden items-center fixed h-full  w-full bg-[rgba(0,0,0,0.41)]"
  // );

  // const showDonateBox = () => {
  //   setDonorBoxDiv(
  //     " flex items-center fixed h-full  w-full bg-[rgba(0,0,0,0.41)]"
  //   );

  // };

  const changeMenuCSScontact = () => {
    tl.current = gsap.timeline();
    console
      .log("hello!")
      .to(
        [exitBtn.current, supportBtn.current,exitBtn2.current, supportBtn2.current],
        { duration: 0, backgroundColor: "#ffe1d9", color: "#b24029" },
        "<0.5"
      );
  };

  const slideIn = () => {
    // console.log("how");
    // setDonorBoxDiv(
    //   " hidden items-center fixed h-full  w-full bg-[rgba(0,0,0,0.41)]"
    // );
    tl.current = gsap
      .timeline()
      // .to(bgColor.current, { duration: 0, backgroundColor: "#ae5238" }, "<0.5")
     
      .to([bgColor.current,] ,{ duration: 0, backgroundColor: "transparent" }, "<0")
      
      .to([topNav.current, menuBtn.current], { duration: 0.25, autoAlpha: 0, display: "none" }, "<")
      .to([overlayMenu.current,SetExitIcon(exitIconM)], { duration: 0.5, left: "0", zIndex: 12 }, "<0.5")
      .to([backButton.current, backButton2.current],{ duration: 0.5, autoAlpha: "1", display: "inline-block",},"<0.25")
      // .to(SetExitIcon(exitIconM), { duration: 0,}, "<+5");
      

    if (props.whichPage !== "contact") {
      console.log("hitting");
      gsap.to([mobDonate.current,exitBtn.current,exitBtn2.current, supportBtn.current,supportBtn2.current], 0.5, {
        delay: 0.5,
        backgroundColor: "#ffe1d9",
        color: "#b24029",
      });
    }
  };



  const slideOut = () => {
    console.log("slide out");
    console.log(window.pageYOffset);
    tl.current = gsap
      .timeline()
      
      .to([backButton.current, backButton2.current],{ duration: 0.25, autoAlpha: 0, display: "none" }, "<")
      .to(overlayMenu.current, { duration: 0.5, left: "-100%", zIndex: 0 }, "<0.5")
      

       .to([topNav.current, menuBtn.current], { duration: 0.5, autoAlpha: "1", display: "inline-block" }, "<0.25")
       .to(SetExitIcon(exitIcon), { duration: 0}, "0.5")
       if (window.pageYOffset > 420) {
        gsap.to([bgColor.current], { duration: 0, backgroundColor: props.whichColor }, "<0.5")

       }

    if (props.whichPage !== "contact") {
      console.log("not contact page");
      gsap.to([mobDonate.current,exitBtn.current, exitBtn2.current,supportBtn.current,supportBtn2.current], 0.5, {
        delay: 0.5,
        backgroundColor: "#b24029",
        color: "#ffe1d9",
      });
      // SetExitIcon(exitIcon);
    }
  };

  //useeffect here

  useEffect(() => {
    if (props.whichPage === "contact") {
      console.log("hitting");
      // setEnglishIconImg(englishIconW);
      SetExitIcon(exitIconM);

      setMobDonate(
        "border-2 text-[#b24029] border-[#b24029] focus:outline-none font-medium rounded-full xsss:text-sm xs:text-[0.675rem] xs:leading-[1.05rem] xsss:px-5 xs:px-[0.9rem] xsss:py-2.5 xs:py-[0.525rem] text-center mr-3"
      );

      setSupportBtn(
        "text-[#b24029] bg-[#ffe1d9] focus:outline-none font-medium rounded-full text-sm px-5 pt-[0.4379rem] pb-[0.4379rem] text-center mr-3"
      );

      setSupportBtn2(
        "text-[#ffe1d9] bg-[#b24029] focus:outline-none font-medium rounded-full xsss:text-sm xs:text-[0.675rem] xs:leading-[1.05rem] xsss:px-5 xs:px-[0.9rem] xsss:py-2.5 xs:py-[0.525rem] text-center mr-3"
      );
      
      setExitBtnClass(
        "text-[#b24029] bg-[#ffe1d9] focus:outline-none font-medium rounded-full text-sm px-5 pt-[0.4379rem] pb-[0.4379rem] text-center"
      );

      setMenuConfig(
        "text-[#ffe1d9]  border border-[#ffe1d9] focus:outline-none font-medium rounded-full text-sm px-4 py-1.5 text-center mr-3"
      );


    }



    var addedScrollDepth = "0";

    window.onscroll = function () {
      const { offsetTop } = props.whichRef.current;

   
      if (window.pageYOffset - addedScrollDepth > offsetTop) {
        gsap.to(bgColor.current, 0, { backgroundColor: props.whichColor });

     setMenuLogo(
    
          "flex w-[100px] pb-[2.2rem] absolute "
        );

        setMenuTop(
          "px-2 sm:px-4 py-[1rem] w-full z-[20] top-0 left-0 border-gray-200 fixed bg-[" +
            props.whichColor +
            "]"
        );
        
        setMenuBotMob(
          "md:hidden fixed bottom-0 xs:h-[4.112rem] xsss:h-[4.506rem] w-[768px] bg-[" +
            props.whichColor +
            "]"
        );
        // "px-2 sm:px-4 py-[1rem] w-full z-20 top-0 left-0 border-gray-200 fixed bg-[#ffddd4]");
      } else {
        gsap.to(bgColor.current, 0, { backgroundColor: "transparent" });
        setMenuTop(
          // "px-2 sm:px-4 py-[1rem] w-full z-20 top-0 left-0 border-gray-200 absolute bg-[#e7a8fc]"
          "px-2 sm:px-4 py-[1rem] w-full z-[20] top-0 left-0 border-gray-200 absolute"
        );

        setMenuLogo(
    
          "hidden w-[100px] pb-[2.2rem] absolute "
        );

        // if(props.whichPage === "homePage"){
        // setMenuBotMob(
        //   "md:hidden fixed bottom-0 xs:h-[4.112rem] xsss:h-[4.506rem] w-[768px]"
        // );
        // }
      }
    };
  },[props.whichPage, props.whichRef, props.whichColor]);

  return (
    <>
      {/* <div className={donorBoxDiv}>
      <iframe className="mx-auto" title="donorboxApp" src="https://donorbox.org/embed/sister-circle?default_interval=m" name="donorbox" allowpaymentrequest="allowpaymentrequest" seamless="seamless" frameborder="0" scrolling="no" height="900px" width="100%" style={styles} ></iframe>
        </div> */}
      <div ref={bgColor} className={menuBotMob}></div>
      <nav ref={bgColor} className={menuTop}>
        {/* <div className="xs:hidden md:flex container flex-wrap md:pl-[15%] items-center justify-between mx-auto max-w-[1240px]"> */}
        <div className="xs:hidden md:flex container flex-wrap  items-center justify-between mx-auto max-w-[1240px]">
          <button href="https://flowbite.com/" className="flex items-center"></button>
          <div className="flex md:order-2">
            <a href="mailto:counselling@sistercircle.org.uk" rel="noreferrer" target="_blank" className="">
              <button ref={supportBtn} type="button" className={getSupportBtn}>
                Get support now
              </button>
            </a>
            <a href="https://www.google.co.uk" className="">
              <button ref={exitBtn} type="button" className={exitBtnClass}>
                Exit site{" "}
                <img
                  ref={changeExit}
                  src={exitIconImg}
                  className="w-[17px] inline-block pb-[2px]"
                  alt="exit_icon"
                />
              </button>
            </a>
          </div>
          <div className="hidden w-full md:flex md:w-auto md:order-1 pl-[16.5rem]" id="navbar-sticky">
            <div ref={topNav} className="m-auto block">
              <button onClick={slideIn} type="button" className={menuConfig}>
                <span className="leading-[0px] text-[19px]">&#x2630;</span> Menu
              </button>
              {/* <a href="https://www.google.co.uk" className="">
                <button type="button" className={menuConfig}>
                  <img src={englishIconImg} className="w-[15px] inline-block pb-[2px]" alt="her0" /> English
                </button>
              </a> */}



              <a href="https://donorbox.org/sister-circle"  rel="noreferrer" target="_blank" className="">
              {/* onclick={showDonateBox} */}
                <button   type="button" className={menuConfig}>
                  &#9825; Donate
                </button>
              </a>
              
            </div>
            <button
              ref={backButton}
              onClick={slideOut}
              type="button"
              className="text-[#ffe1d9] border border-[#ffe1d9] focus:outline-none font-medium rounded-full  leading-4 text-[0.875rem] px-32 py-2 text-center mr-3 hidden opacity-0"
            >
              &#8592; Back
            </button>
          </div>


          {/* ------------------------------- */}
          <div className={menuLogo} id="logoMenu">
          
              <a href="/"><img src={header_logo} className=" z-[21]  xs:w-[90px]  absolute inline " alt="secret-circle-logo" /></a>
            
            
          </div>

          {/* ---------------------------- */}
        </div>
      {/* -----------------------------------------mob top menu button--------------------------------------------------- */}

        <div className="container xs:flex md:hidden flex-wrap items-center justify-between mx-auto max-w-[1240px]">
          <div className={menuLogo} style={styles} id="logoMenu">
            <a href="/"><img src={header_logo} className=" z-[21]  xs:w-[90px]  absolute inline " alt="secret-circle-logo" /></a>
          </div>
          <button  className=""></button>
          <div classsName="xs:fixed top-[20px] right-[20px] md:flex md:order-2">
            {/* <a href="https://www.google.co.uk" className="">
              <button
                type="button"
                className="text-[#ffe1d9] bg-[#b24029] focus:outline-none font-medium rounded-full xsss:text-sm xs:text-[0.675rem] xs:leading-[1.05rem] xsss:px-5 xs:px-[0.9rem] xsss:py-2.5 xs:py-[0.525rem] text-center mr-3"
              >
                <img src={englishIconW} className="w-[15px] inline-block pb-[2px]" alt="her0" /> English
              </button>
            </a> */}
           

           <a href="https://donorbox.org/sister-circle"  rel="noreferrer" target="_blank" className="">
              {/* <button type="button" */}
              <button type="button" ref={mobDonate} className={getMobDonate}>
                &#9825; Donate
              </button>
            </a>
          </div>
{/* -----------------------------------------mob top menu button end--------------------------------------------------- */}

          <div className="xs:fixed bottom-[15px] right-[20px] md:flex md:order-2">
            <button
              ref={backButton2}
              onClick={slideOut}
              type="button"
              className="text-[#b24029]  bg-[#ffe1d9] focus:outline-none font-medium rounded-full text-sm px-12 py-2 xsss:text-sm xs:text-[0.675rem] xs:leading-[1.05rem] xsss:px-8 xs:px-[0.9rem] xsss:py-2.5 xs:py-[0.525rem] text-center mr-3 hidden opacity-0"
            >
              &#8592; Back
            </button>
{/* ---------------------------mobile bottom row buttons---------------------------------------------- */}
            <button
              ref={menuBtn}
              onClick={slideIn}
              type="button"
               
              className="text-[#b24029] border-2 border-[#b24029] focus:outline-none font-medium rounded-full xsss:text-sm xs:text-[0.675rem] xs:leading-[1.05rem] xsss:px-5 xs:px-[0.9rem] xsss:py-2.5 xs:py-[0.525rem] text-center mr-3"
            >
              <span className="leading-[0px] text-[19px]">&#x2630;</span> Menu
            </button>
            <a href="mailto:counselling@sistercircle.org.uk" rel="noreferrer" target="_blank" className="">
              <button
                ref={supportBtn2}
                type="button"
                // className="text-[#ffe1d9] bg-[#b24029] focus:outline-none font-medium rounded-full xsss:text-sm xs:text-[0.675rem] xs:leading-[1.05rem] xsss:px-5 xs:px-[0.9rem] xsss:py-2.5 xs:py-[0.525rem] text-center mr-3"
                className={getSupportBtn2}
              >
                Get support now
              </button>
            </a>
            <a href="https://www.google.co.uk" className="">
              <button
                ref={exitBtn2}
                type="button"
                className="text-[#ffe1d9] bg-[#b24029] focus:outline-none font-medium rounded-full xsss:text-sm xs:text-[0.675rem] xs:leading-[1.05rem] xsss:px-5 xs:px-[0.9rem] xsss:py-2.5 xs:py-[0.525rem] text-center"
              >
                Exit site <img ref={changeExit}
                  src={exitIconImg}  className="w-[17px] inline-block pb-[2px]" alt="exit_icon" />
              </button>
            </a>
{/* --------------------------mob bottom row buttons end----------------------------------------------- */}
          </div>
          <div className="hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
            <div className="m-auto block">
              <button
                onClick={slideIn}
                type="button"
                className="text-[#ffe1d9] bg-[#b24029] focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center mr-3"
              >
                Menu
              </button>
              {/* <a href="https://www.google.co.uk" className="">
                <button
                  type="button"
                  className="text-[#ffe1d9] bg-[#b24029] focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center mr-3"
                >
                  English
                </button>
              </a> */}
              <a href="https://donorbox.org/sister-circle"  rel="noreferrer" target="_blank" className="">
                <button
                  type="button" 
                  className="text-[#ffe1d9] bg-[#b24029] focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center mr-3"
                  // className={getMobDonate}
                >
                  Donate
                </button>
              </a>
            </div>
            <button
              onClick={slideOut}
              type="button"
              className="text-[#ffe1d9] bg-[#b24029] focus:outline-none font-medium rounded-full text-sm px-44 py-2.5 text-center mr-3 hidden opacity-0"
            >
              Back
            </button>
          </div>
        </div>
      </nav>
      <div ref={overlayMenu} className="h-full w-full bg-[#B24029] fixed left-[-100%] pt-[5rem]">
        {/* ---------------------------------------------------------------------------------------------------------------
    --------------------------------------- Slide in page Menu--------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
        <div className="bg-[#B24029]">
          <div className="mx-auto max-w-[1240px]">
            <div className="flex xs:flex-col md:flex-row items-center w-full bg-[#B24029 ] md:min-h-[550px] md:h-[100vh] overflow-hidden">
              <div className=" xs:mb-[0vh] xs:mt-[10vh] md:mt-8  md:mb-8 h-auto xs:w-4/5 md:w-6/12">
                <h1 className="md:mb-2 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-[4.5rem] lgx:w-[392px] font-sans antialiased lgx:text-[4rem] lg:text-[3.6rem] mdx:text-[3.2rem] mds:text-[2.8rem] mds:leading-[3.6rem] s:text-[2.4rem] s:leading-[3.6rem] xss:text-[2.3rem] xss:leading-[2.9rem] align-middle text-[#ffe1d9]">
                  <a className=" hover:underline" href="/">
                    Home
                  </a>
                </h1>
                <h1 className="md:mb-2 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-[4.5rem] lgx:w-[450px] font-sans antialiased lgx:text-[4rem] lg:text-[3.6rem] mdx:text-[3.2rem] mds:text-[2.8rem] mds:leading-[3.6rem] s:text-[2.4rem] s:leading-[3.6rem] xss:text-[2.3rem] xss:leading-[2.9rem] align-middle text-[#ffe1d9]">
                  <a className=" hover:underline" href="/get-support">
                    Get support
                  </a>
                </h1>
                {/* <h1 className="md:mb-2 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-[4.5rem] lgx:w-[550px] font-sans antialiased  lgx:text-[4rem] lg:text-[3.6rem] mdx:text-[3.2rem] mds:text-[2.8rem] mds:leading-[3.6rem] s:text-[2.4rem] s:leading-[3.6rem] xss:text-[2.3rem] xss:leading-[2.9rem] align-middle text-[#ffe1d9]">
                  <a className="hover:text-[#cccccc] hover:underline" href="/">
                    Volunteer with us
                  </a>
                </h1> */}
                {/* <h1 className="md:mb-2 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-[4.5rem] lgx:w-[540px] font-sans antialiased lgx:text-[4rem] lg:text-[3.6rem] mdx:text-[3.2rem] mds:text-[2.8rem] mds:leading-[3.6rem] s:text-[2.4rem] s:leading-[3.6rem] xss:text-[2.3rem] xss:leading-[2.9rem] align-middle text-[#ffe1d9]">
                  <a className="hover:text-[#cccccc] hover:underline" href="/">
                    Partner with us
                  </a>
                </h1> */}
                <h1 className="md:mb-2 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-[4.5rem] lgx:w-[392px] font-sans antialiased lgx:text-[4rem] lg:text-[3.6rem] mdx:text-[3.2rem] mds:text-[2.8rem] mds:leading-[3.6rem] s:text-[2.4rem] s:leading-[3.6rem] xss:text-[2.3rem] xss:leading-[2.9rem] align-middle text-[#ffe1d9]">
                  <a className=" hover:underline" href="/About-us">
                    About us
                  </a>
                </h1>
                {/* <h1 className="md:mb-2 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-[4.5rem] lgx:w-[392px] font-sans antialiased lgx:text-[4rem] lg:text-[3.6rem] mdx:text-[3.2rem] mds:text-[2.8rem] mds:leading-[3.6rem] s:text-[2.4rem] s:leading-[3.6rem] xss:text-[2.3rem] xss:leading-[2.9rem] align-middle text-[#ffe1d9]">
                  <a className="hover:text-[#cccccc] hover:underline" href="/">
                    Our team
                  </a>
                </h1> */}
                {/* <h1 className="md:mb-2 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-[4.5rem] lgx:w-[392px] font-sans antialiased lgx:text-[4rem] lg:text-[3.6rem] mdx:text-[3.2rem] mds:text-[2.8rem]  mds:leading-[3.6rem] s:text-[2.4rem] s:leading-[3.6rem] xss:text-[2.3rem] xss:leading-[2.9rem] align-middle text-[#ffe1d9]">
                  <a className="hover:text-[#cccccc] hover:underline" href="/">
                    Our Impact
                  </a>
                </h1> */}
                <h1 className="md:mb-2 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-[4.5rem] lgx:w-[392px] font-sans antialiased lgx:text-[4rem] lg:text-[3.6rem] mdx:text-[3.2rem] mds:text-[2.8rem] mds:leading-[3.6rem] s:text-[2.4rem] s:leading-[3.6rem] xss:text-[2.3rem] xss:leading-[2.9rem] align-middle text-[#ffe1d9]">
                  <button onClick={changeMenuCSScontact} type="button">
                    <a className=" hover:underline" href="/contact-us">
                      Contact
                    </a>
                  </button>
                </h1>
                {/* <p className=" underline md:mb-2 xs:mt-8 xs:mr-0 xs:ml-0  md:mt-16 md:mr-6  md:ml-[4.5rem] lgx:w-[392px] font-sans antialiased  md:text-[1.1rem] md:leading-[1.1rem] s:text-[0.8rem] s:leading-[1rem] align-middle text-[#ffe1d9]">
                    <a className=""href="/">
                     Make a referral to Maternity Mates
                    </a>
                  
                </p>
                <p className=" underline md:mb-2  xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-[4.5rem] lgx:w-[392px] font-sans antialiased  md:text-[1.1rem] md:leading-[1.1rem] s:text-[0.8rem] s:leading-[1rem] align-middle text-[#ffe1d9]">
                    <a className=""href="/">
                    Become a Maternity Mates
                    </a>
                  
                </p> */}
              </div>
              <div className=" xs:mb-[0vh] xs:mt-[10vh] md:mt-8  md:mb-8 h-auto xs:w-4/5 md:w-6/12">
                <img src={menu_bg} className=" xs:mx-auto md:min-h-[550px] md:max-h-[775px]  md:float-right"alt="hero"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Layout;

Layout.defaultProps = {
  whichColor: "",
  whichRef: "",
  whichPage: "",
 
};
Layout.propTypes = {
   whichColor: PropTypes.string,
  whichRef: PropTypes.string,
   whichPage: PropTypes.string,
  
};

