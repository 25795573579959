import hero from "../images/home_hero.png";
import button from "../images/home_arrowButton.png";
import sc_logo_mob from '../images/logo_home_mobile.png';
import sc_logo_desk from "../images/logo_home_desktop.png";
import sc_logo_desktwo from "../images/logo_red.png";
import getSupport_hero from "../images/home_getSupportHero.png";
import volunteer_hero from "../images/home_volunteerHero.png";
import partner_hero from "../images/home_partnerHero.png";
import about_hero from "../images/home_aboutHero.png";
import impact_hero from "../images/home_impactHero.png";
import Header from "../sections/Header.js";
import Footer from "../sections/Footer.js";
import { useRef } from "react";

const Home = () => {

  // const us =
  const openingComponent = useRef();
  const homePageRef = useRef();

  const arrowScroll = (whichContainer) => {
    whichContainer.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="">
      <Header whichRef={homePageRef} whichPage={"homePage"} whichColor={"#ffe1d9"} />
      
      {/* ---------------------------------------------------------------------------------------------------------------
    ---------------------------------------OPENING HOME content--------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div className="bg-[#eab1fc]">
        <div className="mx-auto max-w-[1240px]">
        {/* <a href="/"><img src={sc_logo_desk} className="w-[213px] absolute hidden md:block" alt="secret-circle-logo" /></a> */}
        <a href="/"><img src={sc_logo_desk} className="w-[213px] absolute hidden md:block" alt="secret-circle-logo" /></a>
        <a href="/"><img src={sc_logo_desktwo} className=" z-[21]  xs:w-[90px]  mt-[1rem] absolute block md:hidden" alt="secret-circle-logo" /></a>
          <div className="flex xs:flex-col-reverse md:flex-row items-center w-full bg-[#eab1fc] overflow-hidden">
            {/* <div className=" md:mb-0 md:mt-0 xs:w-4/5 md:w-6/12 xs:mb-[10vh] xs:mt-[10vh] xs:h-[80vh] xs:min-h-[452px] md:min-h-[208px] grid items-center  md:h-52">  */}
            <div className=" md:mb-0 md:mt-0 xs:w-4/5 md:w-6/12 xs:mt-16 xs:mb-[10vh] md:min-h-[208px] md:h-[13.5rem]">
            <a href="/"><img src={sc_logo_mob} className="w-[182px] md:hidden" alt="secret-circle-logo" /></a>
              <p className=" xs:mt-6 xs:mr-0 xs:ml-0 md:ml-[4.5rem] md:mt-0 md:mr-6  lgx:w-[468px] font-sans font-normal antialiased mds:text-[2.1rem] mds:leading-[2.2rem] xss:text-[1.5rem] xss:leading-[2rem]  align-middle text-[#b24029]">
              We are a charity putting women’s health first. We support women in challenging circumstances to empower themselves and lead healthy lives through service provision, advocacy and peer support.
              </p>
              <button onClick={() => arrowScroll(openingComponent)}>
                <img
                  src={button}
                  className="w-[69px] mt-7 xs:mr-0 xs:ml-0 md:ml-[4.5rem] md:mr-6  "
                  alt="button"
                />
              </button>
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className=" grid xs:items-center md:mt-0  md:mb-0 xs:h-[100vh] md:h-[auto] xs:w-4/5 md:w-6/12">
            <img
                src={hero}
                className="  xs:mx-auto xs:min-w-[256px] xs:min-h-[384px] md:min-h-[550px] md:max-h-[100vh] xs:h-[auto] md:h-[100%] md:float-right"
                alt="hero"
              />
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------------------------------------------------------------
    --------------------------------------- Get support --------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div ref={homePageRef} className="bg-[#ffe1d9]">
        <div ref={openingComponent} className="mx-auto max-w-[1240px]">
          <div className="flex xs:flex-col-reverse md:flex-row items-center w-full bg-[#ffe1d9] md:min-h-[550px] md:h-[48.375rem] overflow-hidden">
            {/* <div className="md:mb-[18vh] md:ml-7 md:mt-[18vh] xs:mb-[20vh] xs:mt-[5vh] xs:w-4/5 md:w-6/12 ">  */}
            <div className=" md:ml-7  xs:mb-[3rem] xs:mt-[3rem] md:mt-0  md:mb-0 xs:w-4/5 md:w-6/12 ">
              <img
                src={getSupport_hero}
                className=" xs:mx-auto max-h-[774px] h-[auto]"
                alt="hero"
              />
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className=" xs:mb-[0vh] xs:mt-[4rem] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12 md:mx-[1.5rem]">
              <h1 className="md:mb-16 xs:mb-0 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-7 lgx:w-[392px] font-sans antialiased lgx:text-[4.8rem] mds:text-[3.8rem] mds:leading-[3.8rem] s:text-[3rem] s:leading-[3rem] xss:text-[2.3rem] xss:leading-[2.3rem] align-middle text-[#b24029]">
              Get support
              </h1>
              <p className="xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7 lgx:w-[355px] font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.54rem] tracking-[-0.01em] align-middle text-[#b24029]">
              If you or someone you know needs support, we have several programs depending on your needs. From one-to-one conversations to group support and education. We are here to help.
              </p>
              <button className="mt-6 xs:mr-0 xs:ml-0  md:mr-6 md:ml-7 block px-5 py-[0.4rem] text-lg font-medium text-center text-[#ffe1d9] bg-[#b24029] rounded-full">
                <a href="/get-support">Learn more &#8594;</a>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------------------------------------------------------------
    --------------------------------------- Volunteer with us --------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div className="bg-[#ffe1d9]">
        <div className="mx-auto max-w-[1240px]">
          <div className="flex xs:flex-col md:flex-row items-center w-full bg-[#ffe1d9] md:min-h-[550px] md:h-[48.375rem] overflow-hidden">
            <div className=" xs:mb-[0vh] xs:mt-[4rem] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12">
              <h1 className="md:mb-16 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-[4.5rem] lgx:w-[392px] font-sans antialiased  lgx:text-[4.8rem] mds:text-[3.8rem] mds:leading-[3.8rem] s:text-[3rem] s:leading-[3rem] xss:text-[2.3rem] xss:leading-[2.3rem] align-middle text-[#b24029]">
              Volunteer<br className="hidden md:block xs:block ss:hidden"></br> with us
              </h1>
              <p className="xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-[4.5rem] lgx:w-[393px] font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.54rem] tracking-[-0.01em] align-middle text-[#b24029]">
              Volunteers are at the heart of what we do. Your contribution can make an enormous difference to the lives of women who find themselves in challenging circumstances. 
              </p>
              <button  className="mt-6 xs:mr-0 xs:ml-0  md:mr-6 md:ml-[4.5rem] block px-5 py-[0.4rem] text-lg font-medium text-center text-[#ffe1d9] bg-[#b24029] rounded-full">
                <a href="mailto:volunteering@sistercircle.org.uk" rel="noreferrer" target="_blank">Contact us &#8594;</a>
              </button>
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className=" md:mr-[4.5rem] md:mb-[18vh] md:mt-[18vh] xs:mb-[3rem] xs:mt-[3rem] xs:w-4/5 md:w-6/12 ">
              <img src={volunteer_hero} className=" xs:mx-auto max-h-[774px] h-[auto]" alt="hero" />
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------------------------------------------------------------
    --------------------------------------- Partner --------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div className="bg-[#ffe1d9]">
        <div className="mx-auto max-w-[1240px]">
          <div className="flex xs:flex-col-reverse md:flex-row items-center w-full bg-[#ffe1d9] md:min-h-[550px] md:h-[48.375rem] overflow-hidden">
            <div className="md:mr-6 md:ml-7 md:mb-[18vh] md:mt-[18vh] xs:mb-[3rem] xs:mt-[3rem] xs:w-4/5 md:w-6/12 ">
              <img src={partner_hero} className=" xs:mx-auto max-h-[774px] h-[auto]" alt="hero" />
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className=" xs:mb-[0vh] xs:mt-[4rem] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12">
              <h1 className="md:mb-16 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-7 lgx:w-[352px] font-sans antialiased lgx:text-[4.8rem] mds:text-[3.8rem] mds:leading-[3.8rem] s:text-[3rem] s:leading-[3rem] xss:text-[2.3rem] xss:leading-[2.3rem] align-middle text-[#b24029]">
              Partner<br className="hidden md:block"></br> with us
              </h1>
              <p className="xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7 lgx:w-[471px] font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.654rem] tracking-[-0.01em] align-middle text-[#b24029]">
              Partnering with other like-minded community organisations and funding partners is critical for us to continue supporting the women in our community.
              </p>
              <button className="mt-6 xs:mr-0 xs:ml-0  md:mr-6 md:ml-7 block px-5 py-[0.4rem] text-lg font-medium text-center text-[#ffe1d9] bg-[#b24029] rounded-full">
              <a href="mailto:karen.wint@sistercircle.org.uk " rel="noreferrer" target="_blank">Contact us &#8594;</a>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------------------------------------------------------------
    --------------------------------------- About --------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div className="bg-[#ffe1d9]">
        <div className="mx-auto max-w-[1240px]">
          <div className="flex xs:flex-col md:flex-row items-center w-full bg-[#ffe1d9] md:min-h-[550px]  md:h-[48.375rem] overflow-hidden">
            <div className=" xs:mb-[0vh] xs:mt-[4rem] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12">
              <h1 className="md:mb-16 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-[4.5rem] lgx:w-[494px] font-sans antialiased lgx:text-[4.8rem] mds:text-[3.8rem] mds:leading-[3.8rem] s:text-[3rem] s:leading-[3rem] xss:text-[2.3rem] xss:leading-[2.3rem] align-middle text-[#b24029]">
              About us
              </h1>
              <p className="xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-[4.5rem] lgx:w-[432px] font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.54rem] tracking-[-0.01em] align-middle text-[#b24029]">
              Working in East London for over 40 years, we work with women in challenging circumstances and promote health equality through education programmes, peer to peer support and service provision.
              </p>
              <button className="mt-6 xs:mr-0 xs:ml-0  md:mr-6 md:ml-[4.5rem] block px-5 py-[0.4rem] text-lg font-medium text-center text-[#ffe1d9] bg-[#b24029] rounded-full">
                <a href="/About-us">Learn more &#8594;</a>
              </button>
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className="md:mr-[4.5rem] md:mb-[18vh] md:mt-[18vh] xs:mb-[3rem] xs:mt-[3rem] xs:w-4/5 md:w-6/12 ">
              <img src={about_hero} className=" xs:mx-auto max-h-[774px] h-[auto]" alt="hero" />
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------------------------------------------------------------
    --------------------------------------- Out Impact --------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div className="bg-[#ffe1d9]">
        <div className="mx-auto max-w-[1240px]">
          <div className="flex xs:flex-col-reverse md:flex-row items-center w-full bg-[#ffe1d9] md:min-h-[550px] md:h-[48.375rem]  overflow-hidden">
            <div className="md:mr-6 md:ml-7 md:mb-[18vh] md:mt-[18vh] xs:mb-[3rem] xs:mt-[3rem] xs:w-4/5 md:w-6/12 ">
              <img src={impact_hero} className=" xs:mx-auto max-h-[774px] h-[auto]" alt="hero" />
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className=" xs:mb-[0vh] xs:mt-[4rem] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12">
              <h1 className="md:mb-16 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-7 lgx:w-[392px] font-sans antialiased lgx:text-[4.8rem] mds:text-[3.8rem] mds:leading-[3.8rem] s:text-[3rem] s:leading-[3rem] xss:text-[2.3rem] xss:leading-[2.3rem] align-middle text-[#b24029]">
              Our impact
              </h1>
              <p className="xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7 lgx:w-[350px] font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.54rem] tracking-[-0.01em] align-middle text-[#b24029]">
              Since 2010, we have supported over 1,000 women and their families with emotional and practical help to ensure better birth outcomes. We also work with survivors of FGM/cutting, and advocate for an end to the practice through outreach and education.
              </p>

              {/* HIDDEN FOR PHASE 1 - DO NOT REMOVE */}
              {/* <button className="mt-6 xs:mr-0 xs:ml-0  md:mr-6 md:ml-7 block px-5 py-[0.4rem] text-lg font-medium text-center text-[#ffe1d9] bg-[#b24029] rounded-full">
                <a href="/">Learn more &#8594;</a>
              </button> */}

            </div>
          </div>
        </div>
      </div>
      {/* --------------------------------------------------content 5 end--------------------------------------------------- */}
      <Footer />
    </div>
  );
};

export default Home;
