import Header from "../sections/Header.js";
import Footer from "../sections/Footer.js";
import { useRef } from "react";

const Cookies = () => {

  const cookiePageRef = useRef();
  const maternityComponent = useRef();

  const h1_Style = "tracking-normal  xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-7  font-sans antialiased  xs:text-2xl align-middle text-[#b24029]" ;
  const h2_Style = "tracking-normal xs:mb-3  xs:mt-3 xs:mr-0 xs:ml-0   md:mr-6  md:ml-7  font-sans antialiased  xs:text-lg align-middle text-[#b24029]" ;
  const h3_Style = "tracking-normal xs:mb-3  xs:mt-3 xs:mr-0 xs:ml-0   md:mr-6  md:ml-7  font-sans antialiased  xs:text-base align-middle text-[#b24029]" ;
  const paragraph_Style = " xs:mr-0 xs:ml-0  md:mr-6 md:ml-7  font-sans antialiased  xs:text-[1rem] xs:leading-5 tracking-normal align-middle text-[#b24029]" ;
  const list_Style = "list-disc pl-8 xs:mt-3 xs:mr-0 xs:ml-0  md:mr-6 md:ml-7  font-sans antialiased  xs:text-[1rem] xs:leading-5 tracking-normal align-middle text-[#b24029]" ;
//   const arrowScroll = (whichContainer) => {
//     whichContainer.current.scrollIntoView({ behavior: "smooth" });
//   };
    return  (<div className="">
        <Header whichRef={cookiePageRef} whichColor={"#ffddd4"} />
{/*-------------------------------------------------------------------------------------------------------------------------------------------------------------------------
-------------------------------------------Cookies-------------------------------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
        <div ref={cookiePageRef} className="bg-[#ffe1d9]">
            <div ref={maternityComponent} className="mx-auto max-w-[1240px] xs:pt-[0vh] md:pt-[9rem] md:pb-[10vh]">
                <div className="flex  items-start flex-row w-full bg-[#ffe1d9] min-h-[100vh] overflow-hidden"> 
                    <div  className="xs:mx-auto xs:mb-[5rem] xs:mt-[5rem] md:mt-0  md:mb-0  xs:w-4/5 md:w-full md:mx-[1.5rem]"> 
                        <h1 className={h1_Style}>
                            Cookie Policy
                        </h1>
                        <h2 className={h2_Style}>
                            What is a Cookie?
                        </h2>
                        <p className={paragraph_Style}>
                            Cookies are small files that are downloaded to your computer, to improve your experience when visiting websites.  This small piece of data is sent from a website and stored in a user's web browser while the user is browsing that website.  Every time the user loads the website, the browser sends the cookie back to the server to notify the website of the user's previous activity. Cookies were designed to be a reliable mechanism for websites to remember  information or to record the user's browsing activity (such as items in a shopping cart, <a className="underline" href="https://en.wikipedia.org/wiki/Authentication" rel="noreferrer" target="_blank">logging in</a>, or recording which pages were visited by the user).  For more general information on cookies see the Wikipedia article on<br/> <a href="http://en.wikipedia.org/wiki/HTTP_cookie"  rel="noreferrer" target="_blank" className="underline break-all">http://en.wikipedia.org/wiki/HTTP_cookie</a>
                        </p>
                        <h2 className={h2_Style}>
                            Disabling Cookies
                        </h2>
                        <p className={paragraph_Style}>
                            You can prevent the setting of cookies by adjusting the settings in your browser (see your browser Help for how to do this).  Be aware that disabling cookies will affect the functionality of this and many other websites that you visit.
                        </p>
                        <h2 className={h2_Style}>
                            The Cookies We Set
                        </h2>
                        <p className={paragraph_Style}>
                            We set the following cookies when you visit our site:
                        </p>
                        <ul className={list_Style}>
                            <li>Acceptance cookie - to record that you have accepted our cookie policy so that the acceptance page does not appear every time you visit a new page.</li>
                            <li>Navigation type cookie - to record the pages you have visited to enable us to improve the navigation and general functionality of the site.</li>
                            <li>Registration and log on cookie – to remember that you are a registered user so that you can access special site features or functionality and to prevent you from having to log in every time you visit a new page.  These cookies are cleared when you log out to ensure that you can only access restricted features and areas when logged in.</li>
                        </ul>
                        <h2 className={h2_Style}>
                            Third Party Cookies
                        </h2>
                        <p className={paragraph_Style}>
                            In some cases cookies may be set by trusted third parties. Below are details of third party cookies you might encounter through this site.
                        </p>
                        <h3 className={h3_Style}>
                            Google Analytics
                        </h3>
                        <p className={paragraph_Style}>
                            This site uses Google Analytics to help us understand how you use the site and ways that we can improve your experience.  These cookies may track things such as how long you spend on the site and the pages that you visit so we can continue to produce engaging content.  For more information on Google Analytics cookies, see the official Google Analytics page <a  href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage"  rel="noreferrer" target="_blank" className="break-all underline">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a>
                        </p>
                        <h3 className={h3_Style}>
                            Social media
                        </h3>
                        <p className={paragraph_Style}>
                        We also use social media buttons and/or plugins on this site that allows you to connect with social networks.  Facebook, Twitter, LinkedIn etc. will set cookies through our site which may be used to enhance your profile on their site or contribute to the data they hold for various purposes outlined in their respective privacy policies.
                        </p>
                        <h3 className={h3_Style}>
                            Embedded content
                        </h3>
                        <p className={paragraph_Style}>
                        Some of the content on our site may be provided by third parties. This includes but is not limited to: Google Maps, YouTube, Vimeo, Flickr etc. When you visit a page containing content from one of these sites a cookie may be set.  We do not have any control over these cookies and you should check the relevant third party website for more information about their use of cookies.
                        </p>
                        <h3 className={h3_Style}>
                            Happy Browsing
                        </h3>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div> );
};

export default Cookies;