import hero from '../images/contact_hero.png';
import sc_logo_desk from '../images/logo_red.png';
import contact_getInTouch from '../images/contact_getInTouch.png';
import contact_map from '../images/contact_map.png';
import contact_map_mobile from '../images/contact_map_mobile.png';
import Header from "../sections/Header.js";
import Footer from "../sections/Footer.js";
import { useRef } from "react";

const Contact = () => {
  const contactPageRef = useRef();
  const getInTouchComponent = useRef();
  const addressComponent = useRef();

  // HIDDEN FOR PHASE 1 - DO NOT REMOVE
  // const gettingHereComponent = useRef();

  const arrowScroll = (whichContainer) => {
    whichContainer.current.scrollIntoView({ behavior: "smooth" });
  };
    return  (<div className="">
       <Header whichRef={contactPageRef} whichPage={"contactPage"} whichColor={"#ffe1d9"} />
    {/* ---------------------------------------------------------------------------------------------------------------
    ---------------------------------------OPENING Contact content--------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div className="bg-[#eab1fc]">
        <div className="mx-auto max-w-[1240px]">
        <a href="/" className='0'><img src={sc_logo_desk} className="mt-[1rem] absolute w-[90px] z-[21]" alt="secret-circle-logo" /></a>
          <div className="flex  md:flex-row items-center w-full bg-[#eab1fc] overflow-hidden"> 
            <div className="max-w-[1240px]  absolute md:mb-0 md:mt-0 w-[100%] "> 

            <h1 className="xs:z-10 xs:relative md:static md:ml-0 xs:text-left md:text-center xs:mb-0  xs:mr-0  xs:ml-[2rem] s:ml-[3rem] sm:ml-[4rem]  md:mt-0  font-sans antialiased mds:text-[4.8rem] mds:leading-[2.2rem] s:text-[3rem] xss:text-[2.8rem] xss:leading-[2.2rem] align-middle text-[#b24029]">Contact us
              </h1>
              <p className="hidden md:block xs:z-10  h-[100%] bottom-[-526%] absolute mx-auto w-[100%] text-center xs:mb-0     md:mt-0  font-sans antialiased mds:text-[0.83rem] md:text-[0.7rem] mds:leading-[1.2rem] s:text-[0.65rem] xss:text-[0.6rem] xss:leading-[1.2rem] align-middle text-[#b24029]"><span><button className='underline underline-offset-3' onClick={() => arrowScroll(getInTouchComponent)}>Get in touch</button></span>&nbsp;&nbsp;&nbsp;&nbsp;<span><button className='underline underline-offset-3' onClick={() => arrowScroll(addressComponent)}>Our address</button></span>
              {/* BUTTON HIDDEN FOR PHASE 1 - DO NOT REMOVE*/}
              {/* &nbsp;&nbsp;&nbsp;&nbsp;<span><button className='underline underline-offset-3' onClick={() => arrowScroll(gettingHereComponent)}>Getting here</button></span> */}
              </p>
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
           
            <div className=" md:mt-0  md:mb-0 w-[100%]  "> 
              {/* <img src={hero} className=" xs:relative md:static xs:left-[20%] md:left-0  xs:mx-auto md:min-h-[388px] md:max-h-[100vh] h-[100%] hero-img float-right" alt="hero" /> */}
              <img src={hero} className=" xs:relative md:static ss:left-[20%] xs:left-[15%] md:left-0  xs:mx-auto md:min-h-[388px] md:max-h-[100vh] ss:h-[100%] xs:h-[188px] ss:mt-0 xs:mt-[18%] hero-img float-right" alt="hero" />
            </div>
           
          </div>
        </div>
      </div>
    {/* ---------------------------------------------------------------------------------------------------------------
    ---------------------------------------Get in touch --------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
      <div ref={contactPageRef} className="bg-[#ffe1d9]">
        <div ref={getInTouchComponent} className="mx-auto max-w-[1240px] xs:pt-[0vh] md:pt-[9rem] md:pb-[8rem]">
          <div  className="flex xs:flex-col xs:items-center md:items-start md:flex-row w-full bg-[#ffe1d9] md:min-h-[550px] overflow-hidden"> 
            {/* <div className="md:mb-[18vh] md:ml-7 md:mt-[18vh] xs:mb-[20vh] xs:mt-[5vh] xs:w-4/5 md:w-6/12 ">  */}
            <div className=" md:ml-8  xs:mb-[7vh] xs:mt-[5vh] md:mt-0  md:mb-0 xs:w-4/5  md:w-[65%] ">
              <h1 className="md:mb-14  xs:mb-11 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-7  font-sans antialiased text-[4.9rem] xl:text-[4.9rem] lgxx:text-[4.9rem] lgx:text-[4.7rem] lg:text-[4.3rem] mdx:text-[3.9rem] mds:text-[3.5rem] md:text-[3rem]  sm:text-[3rem] s:text-[3rem] xss:text-[3rem] leading-[4.5rem] xl:leading-[4.5rem] lgxx:leading-[4.5rem] lgx:leading-[4.3rem] lg:leading-[3.9rem] mdx:leading-[3.5rem] mds:leading-[3.1rem] md:leading-[2.6rem] sm:leading-[2.6rem] s:leading-[2.6rem] xss:leading-[2.6rem] align-middle text-[#b24029]">Get in touch
              </h1>
              <p className="xs:mt-0 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7  font-sans antialiased mds:text-[1.1rem] mds:leading-[1.4rem] xss:text-[1.1rem] xss:leading-[1.4rem] tracking-[-0.01em] align-middle text-[#b24029]">
                <button className="mt-2 xs:mr-0 xs:ml-0  md:mb-4  block px-5 py-[0.4rem] font-medium text-center text-[#b24029] border-2 border-[#b24029] rounded-full" >
                  <a href='mailto:enquiries@sistercircle.org.uk' rel='noreferrer' target="_blank">enquiries@sistercircle.org.uk</a> 
                </button>
                <button className="mt-2 xs:mr-0 xs:ml-0  md:mb-4  block px-5 py-[0.4rem] font-medium text-center text-[#b24029] border-2 border-[#b24029] rounded-full" >
                  <a href='tel:020 7377 8725'>020 7377 8725</a> 
                </button> 
              </p>
              <img src={contact_getInTouch} className="float-left md:ml-8 mt-10 xs:mx-auto max-h-[430px] hidden md:block " alt="maternity 1" />
            </div>
            
            {/* --------------------------------------------------------------------------------------------------------- */}
            <div  className=" xs:mb-[3vh]  md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12 md:mx-[1.5rem]"> 
              <p className=" xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7 lgx:w-[355px] font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.54rem] tracking- [-0.01em] align-middle text-[#b24029]">
              <div className="">
                <h1 className="pb-2 pt-0">Support</h1>
                <ol className="underline decoration-1">
                  <li><a href='mailto:maternity.mates@sistercircle.org.uk' rel='noreferrer' target="_blank">Maternity mates</a></li>
                  <li><a href='mailto:Ameena.Rahman@sistercircle.org.uk' rel='noreferrer' target="_blank">Healing conversations</a></li>
                  <li><a href='mailto:Alexa.Henry@sistercircle.org.uk' rel='noreferrer' target="_blank">Peer support</a></li>
                  <li><a href='mailto:Amal.Hassan@sistercircle.org.uk' rel='noreferrer' target="_blank">Female genital cutting support</a></li>
                  <li><a href='mailto:Amal.Hassan@sistercircle.org.uk' rel='noreferrer' target="_blank">Women’s conversation cafe</a></li>
                </ol>
                <h1 className="pb-2 pt-8">Volunteering</h1>
                <ol className="underline decoration-1">
                  <li><a href='mailto:volunteering@sistercircle.org.uk' rel='noreferrer' target="_blank">General volunteer work</a></li>
                </ol>
                <h1 className="pb-2 pt-8">Partnership</h1>
                <ol className="underline decoration-1">
                  <li><a href='mailto:enquiries@sistercircle.org.uk' rel='noreferrer' target="_blank">Funding partners</a></li>
                </ol>
                <h1 className="pb-2 pt-8">General</h1>
                <ol className="underline decoration-1">
                  <li><a href='mailto:Pauline.facey@sistercircle.org.uk' rel='noreferrer' target="_blank">Finance</a></li>
                </ol>
              </div>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------------------------------------------------------------
    --------------------------------------- Our address --------------------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
    <div className="bg-[#ffe1d9]">
      <div ref={addressComponent} className="bg-[#ffe1d9]">
        <div className="mx-auto max-w-[1240px]">
          <div className="items-center w-full bg-[#ffe1d9] md:min-h-[652px] overflow-hidden"> 
          {/* <div className="flex xs:flex-col-reverse md:flex-row items-center w-full bg-[#ff794e] md:min-h-[652px] overflow-hidden">  */}
            
            {/* --------------------------------------------------------------------------------------------------------- */}
            <div className="  md:mt-0  md:mb-0  md:ml-0 md:mr-0 xs:mx-auto xs:w-4/5 md:w-[100%]"> 
            {/* <div className=" xs:mb-[0vh] xs:mt-[10vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12">  */}
              <p className="border-t border-[#b24029] xs:pt-[50px] md:pt-[110px] xs:pb-0 md:pb-4 xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mx-8 mds:mx-16  font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.654rem] tracking-[-0.01em] align-middle text-[#b24029]">
              {/* <p className="xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-8 mds:ml-16 lgx:w-[376px] font-sans antialiased mds:text-[1.3rem] mds:leading-[1.54rem] xss:text-[1.3rem] xss:leading-[1.654rem] tracking-[-0.01em] align-middle text-[#ffffff]"> */}
              Our address
              </p>
              <h1 className="xs:mb-16 md:mb-16 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-8 mds:ml-16  font-sans antialiased mds:text-[4.8rem] mds:leading-[4.8rem] s:text-[3rem] s:leading-[3.2rem] xss:text-[2.3rem] xss:leading-[2.5rem] align-middle text-[#b24029]">
              {/* <h1 className="md:mb-16 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-6  md:ml-8 mds:ml-16 lgx:w-[352px] font-sans antialiased mds:text-[4.8rem] mds:leading-[4.2rem] s:text-[3rem] s:leading-[4rem] xss:text-[2.3rem] xss:leading-[2.9rem] align-middle text-[#ffffff]"> */}
              Brady Community Centre<br></br>
              192 Hanbury Street<br></br>
              London, E1 5HU
              </h1>
            </div>
            {/* <div className="md:mr-6 md:ml-7 md:mb-[18vh] md:mt-[18vh] xs:mb-[5vh] xs:mt-[5vh] xs:w-4/5 md:w-6/12 "> */}
            <div className="md:mr-0 md:ml-0 md:mt-[7rem] xs:mb-[5rem] md:mb-[9rem] xs:mt-[2rem] w-[100%] ">
              <img src={contact_map} className="xs:hidden md:block xs:mx-auto h-[auto]" alt="hero" />
              <img src={contact_map_mobile} className="md:hidden xs:block xs:mx-auto h-[auto]" alt="hero" />
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* ---------------------------------------------------------------------------------------------------------------
    --------------------------------------- Getting here ---------------------------------------------
    ----------------------------------------------------------------------------------------------------------------- */}
  
  {/* HIDDEN FOR PHASE 1 - DO NOT REMOVE */}

  {/* <div ref={contactPageRef} className="bg-[#ffe1d9]"> 
    <div ref={gettingHereComponent} className="bg-[#ffe1d9]">
      <div className="mx-auto max-w-[1240px] md:pt-[7.4rem]">
        <div className=" xs:items-center md:items-start w-full bg-[#ffe1d9] md:min-h-[550px] overflow-hidden md:ml-0 md:mr-0 xs:mx-auto xs:w-4/5 md:w-[100%]"> 
          <div className=""> 
              <h1 className="xs:mt-5 md:mb-14 xs:mb-0 first-letter:xs:mt-6 xs:mr-0 xs:ml-0  md:mt-0 md:mr-0  md:ml-[4.5rem] font-sans antialiased text-[4.9rem] xl:text-[4.9rem] lgxx:text-[4.9rem] lgx:text-[4.7rem] lg:text-[4.3rem] mdx:text-[3.9rem] mds:text-[3.5rem] md:text-[3rem]  sm:text-[3rem] s:text-[3rem] xss:text-[3rem] leading-[4.5rem] xl:leading-[4.5rem] lgxx:leading-[4.5rem] lgx:leading-[4.3rem] lg:leading-[3.9rem] mdx:leading-[3.5rem] mds:leading-[3.1rem] md:leading-[2.6rem] sm:leading-[2.6rem] s:leading-[2.6rem] xss:leading-[2.6rem]  align-middle text-[#b24029]">
              Getting here
              </h1>
              <div className="xs:pt-[20px] xs:pb-[50px]  pt-[50px] pb-[100px] xs:mt-8   first-letter:xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0  md:mx-[4.5rem]  font-sans antialiased mds:text-[1.1rem] mds:leading-[1.34rem] xss:text-[1.1rem] xss:leading-[1.34rem] tracking-[-0.01em] align-middle text-[#b24029]">
                <div className="py-5  border-t border-[#b24029] flex xs:flex-col md:flex-row items-start w-full  overflow-hidden"> 
                  <div className=" xs:mb-[1vh] xs:mt-[1vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-4/12">
                    By tube
                  </div> 
                  <div className=" xs:mb-[0vh] xs:mt-[1vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-8/12">
                    Whitechapel: Name Line, Name Line (15 minute walk)<br></br>
                    Aldgate and Aldgate East:  Name Line, Name Line, Name Line (10 minute walk)<br></br>
                    Liverpool Street: Name Line, Name Line, Name Line (12 minute walk)<br></br>
                    Shoreditch High Street: Name Line, Name Line (15 minute walk)
                  </div>
                </div>
                <div className="py-5 border-t border-[#b24029] flex xs:flex-col md:flex-row items-start w-full   overflow-hidden"> 
                  <div className=" xs:mb-[1vh] xs:mt-[1vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-4/12">
                    By bus
                  </div> 
                  <div className=" xs:mb-[1vh] xs:mt-[1vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-8/12">
                    Route XXX from Name Road (3 minute walk)<br></br>
                    Routes XXX, XXX, XXX, and XXX from Name Road (5 minute walk)<br></br>
                    Routes XXX and XXX from Name Street (6 minute walk)<br></br>
                    Route XXX from Name Road (9 minute walk)
                  </div>
                </div>
                <div className="py-5 border-t border-[#b24029] flex xs:flex-col md:flex-row items-start w-full   overflow-hidden"> 
                  <div className=" xs:mb-[1vh] xs:mt-[1vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-4/12">
                    By bike
                  </div> 
                  <div className=" xs:mb-[1vh] xs:mt-[1vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-8/12">
                    Bike locks... empori con et, sero ent quia voloressim que eos dente nonsectem vello vollate mporemquam ut voluptatem illit quia ipiet, autat id magnam, aut aborest eum es dem vendend ignimax iminctem reius, velit pratecum quibus qui untis dolupti untemque doluptam sincto versper ovitiore volor rem sim ut laccull andus.
                  </div>
                </div>
                <div className="py-5  border-t border-[#b24029] flex xs:flex-col md:flex-row items-start w-full   overflow-hidden"> 
                  <div className=" xs:mb-[1vh] xs:mt-[1vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-4/12">
                    By car
                  </div> 
                  <div className=" xs:mb-[1vh] xs:mt-[1vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-8/12">
                    Where to park... empori con et, sero ent quia voloressim que eos dente nonsectem vello vollate mporemquam ut voluptatem illit quia ipiet, autat id magnam, aut aborest eum es dem vendend ignimax iminctem reius, velit pratecum quibus qui untis dolupti untemque doluptam sincto versper ovitiore.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}


    <Footer />
    </div> 
  );
};

  
  export default Contact;