import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Support from "./pages/Support";
import Volunteer from "./pages/Volunteer";
import About from "./pages/About";
import Contact from "./pages/Contact";
import './fonts/Universal-Sans-Display-330.ttf';
import Cookies from "./pages/Cookies";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="Get-Support" element={<Support />} />
          <Route path="Volunteer-with-us" element={<Volunteer />} />
          <Route path="About-us" element={<About />} />
          <Route path="Contact-us" element={<Contact />} />
          <Route path="Cookies" element={<Cookies />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
