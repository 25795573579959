import twitter from "../images/footer_twitter.png";
import facebook from "../images/footer_facebook.png";
import instagram from "../images/footer_instagram.png";
import donate from "../images/footer_donate.png";
// import globe from "../images/english_IconW.png";
// import Header from "../sections/Header.js";
// import { useRef } from "react";

const Footer = () => {
//   const contactPageRef = useRef();
  return (
    <div className="">
      {/* <Header whichRef={contactPageRef} whichPage={"contactPage"} whichColor={"transparent"} /> */}
    
      {/* ---------------------------------------------------------------------------------------------------------------
      ---------------------------------------Footer section--------------------------------------------------------
      ----------------------------------------------------------------------------------------------------------------- */}
      <div className="bg-[#b24029] xs:pb-16 md:pb-0">
        <div className="mx-auto max-w-[1240px]">
          <div className="flex xs:pt-[6rem] xs:mb-0 md:mb-[3.8rem] md:pt-0 xs:flex-col-reverse md:flex-row xs:items-center md:items-end w-full bg-[#b24029] md:min-h-[510px] overflow-hidden">
            <div className="xs:hidden md:block md:w-[450px] mds:w-[380px] xs:w-[100%]      align-bottom">
              <button className="mt-6 xs:mr-0 xs:ml-0  md:mr-6   md:ml-7 block px-8 pt-[0.7rem] pb-[0.7rem] text-[1.8rem] font-medium text-center text-white bg-[#ff794e] rounded-full">
              <a href="https://donorbox.org/sister-circle"  rel="noreferrer" target="_blank" className="">
                  <img src={donate} className="w-[25px] inline mr-2 mb-1.5" alt="donate" />
                  Donate
                </a>
              </button>
              <button className="mt-6 xs:mr-0 xs:ml-0  md:mr-6 md:ml-7 block px-8 pt-[0.7rem] pb-[0.8rem]  lgx:text-[1.8rem] mdx:text-[1.6rem] md:text-[1.3rem]   font-medium text-center text-[#b24029] bg-[#eab1fc] rounded-full">
                <a href="mailto:counselling@sistercircle.org.uk" rel="noreferrer" target="_blank">
                  Get support now
                </a>
              </button>
            </div>
            {/* <div className="xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7 lgx:w-[355px] font-sans antialiased mds:text-[1rem] mds:leading-[1.24rem] xss:text-[1rem] xss:leading-[1.24rem] tracking-[-0.01em] align-middle text-[#ffe1d9]"> */}
            {/* <div className=" xs:mb-[0vh] xs:mt-[10vh] md:mt-0  md:mb-0  xs:w-4/5 md:w-6/12 md:mx-[1.5rem]">  */}
            <div className="md:w-[330px] xs:w-4/5    md:mr-[1.5rem]    font-sans antialiased text-[1rem] leading-[1.24rem] tracking-[-0.01em] text-[#ffe1d9]">
              <h1 className="pb-1 decoration-1">Address</h1>
              <p className="pb-0 pt-0 mb-0 mt-0">
                Brady Community Centre
                <br />
                192 Hanbury Street
                <br />
                London E1 5HU
              </p>
              <button className="mt-6 xs:mr-0 xs:ml-0  md:mr-0 md:ml-0 block px-4 py-[0.4rem] text-[1rem] font-medium text-   text-[#ffe1d9] border-[#ffe1d9] border-2  border-solid rounded-full">
                <a href="https://goo.gl/maps/XCBgucHYgPjuUirh8" rel="noreferrer" target="_blank">Google Maps</a>
              </button>
              <h1 className="pb-1 pt-10">Site map</h1>
              <ol className="underline decoration-1 xs:mb-0 md:mb-[4.5rem]">
                <li><a href="/">Homepage</a></li>
                <li><a href="/get-support">Get support</a></li>
                {/* <li>Volunteer with us</li> */}
                {/* <li>Partner with us</li> */}
                <li><a href="/About-us">About us</a></li>
                {/* <li>Our team</li> */}
                {/* <li>Site map</li> */}
                {/* <li>Impact</li> */}
                <li><a href="/contact-us">Contact</a></li>
              </ol>
              <h1 className="pb-2 pt-5 align-bottom">Follow us</h1>
              <a href='https://www.instagram.com/sistercirclelondon/' rel='noreferrer' target="_blank"><img src={instagram} className="float-left h-[22px] pr-4" alt="instagram" /></a>
              <a href='https://twitter.com/SisterCircle_' rel='noreferrer' target="_blank"><img src={twitter} className="float-left h-[22px] pr-4" alt="twitter" /></a>
              <a href='http://facebook.com/sistercirclelondon' rel='noreferrer' target="_blank"><img src={facebook} className="float-left h-[22px]" alt="facebook" /></a>
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}

            {/* <div className="xs:mt-8 xs:mr-0 xs:ml-0 md:mt-0 md:mr-6 md:ml-7 lgx:w-[355px] font-sans antialiased mds:text-[1rem] mds:leading-[1.24rem] xss:text-[1rem] xss:leading-[1.24rem] tracking-[-0.01em] align-middle text-[#ffe1d9]"> */}
            <div className="md:w-[350px] xs:w-4/5   md:mr-[1.5rem]   font-sans antialiased text-[1rem] leading-[1.24rem] tracking-[-0.01em] text-[#ffe1d9]">
              <h1 className="pb-0">Get in Touch</h1>
              <p className="pb-0 b-0 underline decoration-1 mds:text-[1.4rem] mds:leading-[1.64rem] xss:text-[1.4rem] xss:leading-[1.64rem]">
                <a href='mailto:enquiries@sistercircle.org.uk' rel='noreferrer' target="_blank">enquiries@sistercircle.org.uk</a> 
                </p>
                <p className="pb-10 b-0 underline decoration-1 mds:text-[1.4rem] mds:leading-[1.64rem] xss:text-[1.4rem] xss:leading-[1.64rem]"> <a href="tel:020 7377 9644">020 7377 9644</a> </p>
            

              <div className="xs:hidden md:block">
                <h1 className="pb-1 pt-0">Support</h1>
                <ol className="underline decoration-1">
                <li><a href='mailto:maternity.mates@sistercircle.org.uk' rel='noreferrer' target="_blank">Maternity mates</a></li>
                  <li><a href='mailto:Ameena.Rahman@sistercircle.org.uk' rel='noreferrer' target="_blank">Healing conversations</a></li>
                  <li><a href='mailto:Alexa.Henry@sistercircle.org.uk' rel='noreferrer' target="_blank">Peer support</a></li>
                  <li><a href='mailto:Amal.Hassan@sistercircle.org.uk' rel='noreferrer' target="_blank">Female genital cutting support</a></li>
                  <li><a href='mailto:Amal.Hassan@sistercircle.org.uk' rel='noreferrer' target="_blank">Women’s conversation cafe</a></li>
                </ol>
                <h1 className="pb-1 pt-5">Volunteering</h1>
                <ol className="underline decoration-1">
                <li><a href='mailto:volunteering@sistercircle.org.uk' rel='noreferrer' target="_blank">General volunteer work</a></li>
                  {/* <li>Maternity mates</li> */}
                  {/* <li>Development volunteer</li> */}
                </ol>
                <h1 className="pb-1 pt-5">Partnership</h1>
                <ol className="underline decoration-1">
                <li><a href='mailto:enquiries@sistercircle.org.uk' rel='noreferrer' target="_blank">Funding partners</a></li>
                  {/* <li>Other ways to support</li> */}
                </ol>
                <h1 className="pb-1 pt-5">General</h1>
                <ol className="underline decoration-1">
                  {/* <li>PR and Communication</li> */}
                  <li><a href='mailto:Pauline.facey@sistercircle.org.uk' rel='noreferrer' target="_blank">Finance</a></li>
                  {/* <li>IT & Resources</li> */}
                </ol>
              </div>
            </div>
          </div>
          <div className=" bg-[#b24029] text-[#ffe1d9] text-[0.7rem] p-7 border-t-[1px] mt-7">
            <h1 className="inline-block  pr-1">© 2023 Sister Circle.</h1>
            {/* <h1 className="inline-block  pl-1 float-right">
              <img src={globe} className="w-[15px] inline mr-1" alt="globe" />
              English
            </h1> */}
            <ol className="inline-block xs:pt-2 md:pt-0">
            <li className="inline-block px-1">Registered charity in England and Wales (1033764)</li>
            <li className="inline-block px-1"><a href='/Cookies'>Cookie Policy</a></li>
              {/* <li className="inline-block px-1">Ethical</li>
              <li className="inline-block px-1">Complaints</li>
              <li className="inline-block px-1">Accessibility</li>
              <li className="inline-block px-1">Modern Slavery</li>
              <li className="inline-block px-1">Cookies Disclaimer</li>
              <li className="inline-block px-1">Employee Privacy</li>
              <li className="inline-block px-1">Safeguarding</li>
              <li className="inline-block px-1">Disclaimer</li>
              */}
           
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
